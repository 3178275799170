"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlocksTab = void 0;
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var Carousel_1 = require("Project/ContentSidebar/Explore/SharedComponents/Carousel");
var react_1 = __importStar(require("react"));
var styles = __importStar(require("./BlocksTab.css"));
var BlockThumbnail_1 = require("./BlockThumbnail");
var Types_1 = require("Project/ContentSidebar/Types");
var SidebarExploreContext_1 = require("../SidebarExploreContext");
var lodash_1 = require("lodash");
var inversify_react_1 = require("inversify-react");
var ExploreEmptyState_1 = require("../SharedComponents/ExploreEmptyState");
var SidebarExplore_1 = require("Project/ContentSidebar/Explore/SidebarExplore");
var carouselOrder = [
    "introductions",
    "executive summaries",
    "product features",
    "timelines",
    "return on investment",
    "meet the team",
    "pricing & quotes",
    "agreements & acceptance",
    "terms & conditions",
];
var BlocksTab = function (_a) {
    var onClickToAdd = _a.onClickToAdd, onDragAssetEnd = _a.onDragAssetEnd, onDragAssetStart = _a.onDragAssetStart;
    var thumbnailApiBaseUrl = (0, inversify_react_1.useInjection)("thumbnailApiBaseUrl");
    var _b = (0, SidebarExploreContext_1.useSidebarExploreContext)(), store = _b.blockExploreDataStore, searchTerm = _b.searchTerm;
    var _c = __read((0, react_1.useState)([]), 2), blocks = _c[0], setBlocks = _c[1];
    (0, react_1.useEffect)(function () {
        if (searchTerm) {
            store.search(searchTerm);
        }
        else {
            store.clearSearch();
        }
        setBlocks(store.loadedAssets);
    }, [searchTerm, store]);
    var categories = Object.entries((0, lodash_1.groupBy)(blocks, "content.savedBlockName"));
    var sortedCategories = (0, lodash_1.sortBy)(categories, function (_a) {
        var _b = __read(_a, 1), category = _b[0];
        var sortOrder = (0, lodash_1.indexOf)(carouselOrder, category.toLowerCase());
        return sortOrder !== -1 ? sortOrder : 999;
    });
    var showEmptyState = blocks.length === 0;
    return (react_1.default.createElement(kaleidoscope_1.Stack, { paddingLeft: "l", paddingRight: "l", className: styles.container, gap: "l", "data-testid": "sidebar-blocks-list" }, showEmptyState ? (react_1.default.createElement(ExploreEmptyState_1.ExploreEmptyState, { assetType: Types_1.AssetType.Block, searchTerm: searchTerm })) : (sortedCategories.map(function (_a) {
        var _b = __read(_a, 2), category = _b[0], categoryAssets = _b[1];
        return (react_1.default.createElement(kaleidoscope_1.Stack, { key: category },
            react_1.default.createElement(kaleidoscope_1.Text, { size: "s", strong: true, lineHeight: "relaxed" }, category),
            react_1.default.createElement(Carousel_1.Carousel, { itemsPerSlide: 2, items: categoryAssets, className: styles.carousel, renderItem: function (asset) { return (react_1.default.createElement(BlockThumbnail_1.BlockThumbnail, { asset: asset, thumbnailImage: store.getThumbnailUrl(thumbnailApiBaseUrl, asset.content.id), onClick: function () {
                        onClickToAdd(asset, { tab: SidebarExplore_1.SidebarExploreTab.Blocks, withSearchTerm: !!searchTerm });
                    }, onDragStart: function () {
                        onDragAssetStart(asset, { tab: SidebarExplore_1.SidebarExploreTab.Blocks, withSearchTerm: !!searchTerm });
                    }, onDragEnd: function () {
                        onDragAssetEnd(asset);
                    } })); } })));
    }))));
};
exports.BlocksTab = BlocksTab;
