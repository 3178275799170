import "Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6WVwW7iMBCG7zzFCGkl0GIUhwRoUI/7AntdVciJBzANdtY2lLLad1/FCU1sqNpVxcmZL/8/mRkP0zWV4pJfeAR/BgCVMsIKJTPQWDIrTrgaAOxQbHc2AxpF31aDv4Pp9SXqXnoR3O7uRON+dCMsKZS0KG0N/eLMMmKwxKL2I1rI7ZPji6M2SmdQKSEt6g52R1bUSZGLkvg0aUVKZpFIxfFxiCUeUNqhL8Vxw45lz7fFiH2t8HFo8Wx/3H2xjnifNHdhQq7nJINo5dWN5UaVR+vqdudTOqUsy3GjNDaGTWUyGA7fl8vVmZgd4+olg8j94uoMJ6ZHXULpuCZVxQphX7MgmLhgzornrVZHyUmhSveZmklTMe16A82x9W+VWiF+1My1y6zXNC8X+z2lZtwGkRkht8RYJjnTvCN+jye93L8uFeT/RUGvK4vb/tKV9yTNQG/zUTxLIU4iiJOFr7D8rAJNHyCmCcRp4is8fFphDjSdQZzGvgBzAkIatG48+yFv6jqkHi7NURPNuDiacHBmE4iqs++S9yUIjauzH77nlNTQx17Ng4b66SC3OrQqu8bh2E+Ht/vL3TSCJ5TWZCCVdFfnmmYSZMnfr8fNLalLvlwCXdY9m43DG9lY9bQ3d3bFlHrI9h4Se8g+2Mv9hWBVFVZPuLx0s68LVhajei3D9wDbBdXb//82cubt6FirDu2hxM3bP8Xb8g/NPe/noAgiHNryFiA0Cjp5CKBdBvMAkbcIDQeiumWWDvkHbuL8LykHAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var ringDataAttribute = 'data-selection-ring';
export var interactiveElementSelector = '[data-interactive-zone]';
export var textElementSelector = '[data-element-type="textElement"]';
export var childSelector = '[data-slate-node="element"]';
export var selectionRingWidth = 2;
export var wrapper = _7a468({defaultClassName:'_1nizbzd0',variantClassNames:{widgetWidth:{'full-width':'_1nizbzd1','fit-widget':'_1nizbzd2'}},defaultVariants:{},compoundVariants:[]});
export var ringBorderRadius = 'var(--_1nizbzd3)';
export var ring = _7a468({defaultClassName:'_1nizbzd6',variantClassNames:{mouseOverWidget:{true:'_1nizbzd7'},mouseOverSelectableRegion:{true:'_1nizbzd8'},selected:{true:'_1nizbzd9'},mode:{hugging:'_1nizbzda',outside:'_1nizbzdb'},nodeType:{'void':'_1nizbzdc',textElement:'_1nizbzdd',layoutElement:'_1nizbzde'}},defaultVariants:{},compoundVariants:[[{mouseOverSelectableRegion:true,nodeType:'textElement'},'_1nizbzdf'],[{selected:true,nodeType:'textElement'},'_1nizbzdg']]});
export var dragHandle = _7a468({defaultClassName:'_1nizbzdj',variantClassNames:{alignment:{widget:'_1nizbzdk','selection-ring':'_1nizbzdl'},mode:{hugging:'_1nizbzdm',outside:'_1nizbzdn'},nodeType:{'void':'_1nizbzdo',textElement:'_1nizbzdp',layoutElement:'_1nizbzdq'}},defaultVariants:{},compoundVariants:[]});