"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutomationAssignment = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var TemplatesSelector_1 = require("Common/Templates/TemplatesSelector");
var styles = __importStar(require("./AutomationAssignment.css"));
var icons_1 = require("@qwilr/kaleidoscope/icons");
var AutomationAssignment = function (_a) {
    var associations = _a.associations, onAssociationsChange = _a.onAssociationsChange, onIsGloballyAssignedChange = _a.onIsGloballyAssignedChange, supportedTemplates = _a.supportedTemplates, errorMessage = _a.errorMessage, errorIds = _a.errorIds, canBeGloballyAssigned = _a.canBeGloballyAssigned, initialIsGloballyAssigned = _a.initialIsGloballyAssigned;
    var _b = __read((0, react_1.useState)((associations === null || associations === void 0 ? void 0 : associations.map(function (association) { return association.resourceId; })) || []), 2), selectedTemplateIds = _b[0], setSelectedTemplatesIds = _b[1];
    var _c = __read((0, react_1.useState)(initialIsGloballyAssigned), 2), isGloballyAssigned = _c[0], setIsGloballyAssigned = _c[1];
    var handleSetSelectedTemplates = function (selectedTemplateIds) {
        setSelectedTemplatesIds(selectedTemplateIds);
        onAssociationsChange(selectedTemplateIds.map(function (templateId) {
            return { resourceType: "template", resourceId: templateId };
        }));
    };
    var handleIsGloballyAssignedChange = function () {
        var nextIsGloballyAssigned = !isGloballyAssigned;
        setIsGloballyAssigned(nextIsGloballyAssigned);
        var associations = nextIsGloballyAssigned
            ? []
            : selectedTemplateIds.map(function (templateId) {
                return { resourceType: "template", resourceId: templateId };
            });
        onIsGloballyAssignedChange(nextIsGloballyAssigned, associations);
    };
    return (react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.automationAssignment },
        react_1.default.createElement(kaleidoscope_1.Card, null,
            react_1.default.createElement(kaleidoscope_1.Stack, { padding: "l", gap: "s" },
                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal" },
                    react_1.default.createElement(kaleidoscope_1.Stack, null,
                        react_1.default.createElement(kaleidoscope_1.Text, { strong: true, size: "m" }, "Assign automation"),
                        react_1.default.createElement(kaleidoscope_1.Text, { secondary: true, size: "s" },
                            "Choose which templates you want to assign this automation. You can also assign automations later from the",
                            " ",
                            react_1.default.createElement(kaleidoscope_1.Link, { href: "/#/templates", target: "_blank" }, "templates dashboard.")))),
                react_1.default.createElement(kaleidoscope_1.Stack, null,
                    react_1.default.createElement(TemplatesSelector_1.TemplatesSelector, { selectedTemplates: selectedTemplateIds, setSelectedTemplates: handleSetSelectedTemplates, templatesFilter: { withCrmInfo: supportedTemplates }, errorMessage: errorMessage, errorIds: errorIds, disabled: isGloballyAssigned, disabledReason: isGloballyAssigned
                            ? "Unselect the \"All pages\" option to assign this automation to templates."
                            : undefined }),
                    canBeGloballyAssigned ? (react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", justify: "center", align: "center" },
                            react_1.default.createElement("hr", { className: styles.divider }),
                            react_1.default.createElement(kaleidoscope_1.Text, { className: styles.dividerText }, "or"),
                            react_1.default.createElement("hr", { className: styles.divider })),
                        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", gap: "xxs" },
                            react_1.default.createElement(kaleidoscope_1.Checkbox, { size: kaleidoscope_1.CheckboxSize.Medium, item: { id: "isGloballyAssigned", label: "All pages", value: "" }, isSelected: isGloballyAssigned, onChange: handleIsGloballyAssignedChange }),
                            react_1.default.createElement(kaleidoscope_1.Tooltip, { size: kaleidoscope_1.TooltipSize.Large, content: "This option will assign this automation to all pages in your account and run for any page that reaches the selected trigger." },
                                react_1.default.createElement(icons_1.Help, { color: "default" }))))) : null)))));
};
exports.AutomationAssignment = AutomationAssignment;
