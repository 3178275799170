"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizeBlock = void 0;
var slate_1 = require("slate");
var lodash_1 = require("lodash");
var EditorWidgetRegistry_1 = require("Project/QwilrEditor/WidgetRegistry/EditorWidgetRegistry");
var GetTraitConfig_1 = require("@CommonFrontend/WidgetRegistry/Utils/GetTraitConfig");
var normalizeBlock = function (_a, editor, schema) {
    var _b, _c;
    var _d = __read(_a, 2), node = _d[0], path = _d[1];
    if ((0, EditorWidgetRegistry_1.isResizeableWidget)(node)) {
        var width = node.data.width;
        var height = node.data.height;
        var resizeableConfiguration = (0, GetTraitConfig_1.getTraitConfig)({ widgetType: node.type, traitName: "resizeable" });
        if (typeof width === "undefined") {
            var nextData = (0, lodash_1.cloneDeep)(node.data);
            nextData.width = resizeableConfiguration.width.default;
            slate_1.Transforms.setNodes(editor, { data: nextData }, { at: path });
            return true;
        }
        if (typeof height === "undefined" && resizeableConfiguration.height) {
            var nextData = (0, lodash_1.cloneDeep)(node.data);
            nextData.height = resizeableConfiguration.height.default;
            slate_1.Transforms.setNodes(editor, { data: nextData }, { at: path });
            return true;
        }
        if (typeof width === "string" && width !== "content") {
            var parentEntry = slate_1.Editor.parent(editor, path);
            var _e = __read(parentEntry, 1), parentNode = _e[0];
            if (!slate_1.Editor.isEditor(parentNode)) {
                var parentConfig = (0, EditorWidgetRegistry_1.getGenericConfigByType)(parentNode.type, true);
                if (!((_b = parentConfig === null || parentConfig === void 0 ? void 0 : parentConfig.containerNodeConfiguration) === null || _b === void 0 ? void 0 : _b.supportWideWidgets)) {
                    var nextData = (0, lodash_1.cloneDeep)(node.data);
                    nextData.width = resizeableConfiguration.width.custom ? 100 : "content";
                    slate_1.Transforms.setNodes(editor, { data: nextData }, { at: path });
                    return true;
                }
            }
        }
    }
    if ((0, EditorWidgetRegistry_1.isAlignableWidget)(node)) {
        var alignment = node.data.alignment;
        var alignableConfiguration = (0, GetTraitConfig_1.getTraitConfig)({ widgetType: node.type, traitName: "alignable" });
        if (typeof alignment === "undefined") {
            var nextData = (0, lodash_1.cloneDeep)(node.data);
            nextData.alignment = alignableConfiguration.defaultAlignment;
            slate_1.Transforms.setNodes(editor, { data: nextData }, { at: path });
            return true;
        }
    }
    if ((0, EditorWidgetRegistry_1.isStyleableWidget)(node) && !node.style) {
        slate_1.Transforms.setNodes(editor, { style: {} }, { at: path });
        return true;
    }
    var editorRegistryNormalizerResult = (_c = (0, EditorWidgetRegistry_1.getGenericConfigByType)(node.type, true)) === null || _c === void 0 ? void 0 : _c.normalizeWidget(node, path, editor, schema);
    if (!(0, lodash_1.isUndefined)(editorRegistryNormalizerResult)) {
        return editorRegistryNormalizerResult;
    }
    if (schema.blocks[node.type] == undefined) {
        return false;
    }
    return false;
};
exports.normalizeBlock = normalizeBlock;
