import "Application/Library/AutomationLibrary/AutomationActivityLog.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Library/AutomationLibrary/AutomationActivityLog.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6XS3W6CMBgG4HOuoidLtoMa8GcqHu4GlngBprQFiqUfK0Vdlt37Ik6kqPyEQ5L36fuVfpOdF3zFkEsX/TgIHQUzsY+ms5eNgxAcuA4lHH0UC8a42ji/zuQKvDqYd+enVsGiG8yGgnkdeD2usLCA2w3eSxBKfvKRi1xECgNnREGC9tGB6FeMyw8sKKjdzgvkMkm87M06ZlkekxIdCYUNZFeZZ4QKFeG0gt7elqtSZoQxoaIOhlBA6D7SUCiGH0yYFzoklG85BcWI/q60sDvXgzr/bxWAMZA20/ktnfSf8EMLIyiRFU7tAUk54ANv+Mnc4bWNgzoWKuZaGCtA207fFpTy/HatpX04GzMZb8WkMKK2YSvbhqUNQDOusSZMFLmPFucNr4WiS6jXkjTueXnrJ2vfDBt7trh/7cOXf9p7lz7YxWJAceP3ytbeRriwa5OuDduPeGg5wqZttkJzG6kxKw1t+FMSymOQjOvKB2f/BxkDEJSkBgAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var col1 = '_1bqhosl0';
export var col2 = '_1bqhosl1';
export var col3 = '_1bqhosl2';
export var col4 = '_1bqhosl3';
export var col5 = '_1bqhosl4';
export var col6 = '_1bqhosl5';
export var icon = '_1bqhosl6';
export var expandableContent = '_1bqhosl7';
export var executionOverview = '_1bqhosl8';
export var executionErrorOverview = '_1bqhosl9';
export var executionErrorText = '_1bqhosla';
export var statusText = _7a468({defaultClassName:'_1bqhoslb',variantClassNames:{outcome:{success:'_1bqhoslc',failure:'_1bqhosld',review:'_1bqhosle'}},defaultVariants:{},compoundVariants:[]});
export var statusIcon = _7a468({defaultClassName:'_1bqhoslf',variantClassNames:{outcome:{success:'_1bqhoslg',failure:'_1bqhoslh',review:'_1bqhosli'}},defaultVariants:{},compoundVariants:[]});
export var actionText = _7a468({defaultClassName:'_1bqhoslj',variantClassNames:{outcome:{'partial-success':'_1bqhoslk','not-executed':'_1bqhosll',success:'_1bqhoslm',failure:'_1bqhosln'}},defaultVariants:{},compoundVariants:[]});
export var notCreatedFromTemplateText = '_1bqhoslo';