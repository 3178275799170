import "Application/Project/ContentSidebar/ContentSidebar.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/ContentSidebar/ContentSidebar.css.ts.vanilla.css\",\"source\":\"LnhndGU1NTAgewogIGJveC1zaGFkb3c6IHZhcigtLXNoYWRvdy1lbGV2YXRpb24xX18xYmw3amoxMWIpOwp9Ci54Z3RlNTUxIHsKICB3aWR0aDogMzYwcHg7CiAgYWxpZ24tc2VsZjogZmxleC1lbmQ7CiAgYm9yZGVyLWxlZnQ6IDFweCBzb2xpZCB2YXIoLS1jb2xvci1ib3JkZXJfXzFibDdqajF5KTsKfQoueGd0ZTU1MiB7CiAgYm94LXNoYWRvdzogdmFyKC0tc2hhZG93LWVsZXZhdGlvbjFfXzFibDdqajExYik7Cn0KLnhndGU1NTMgewogIGxlZnQ6IC0yNzhweDsKfQoueGd0ZTU1NCB7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwogIGxlZnQ6IDA7Cn0KLnhndGU1NTUgewogIGJvcmRlci1sZWZ0OiAxcHggc29saWQgdmFyKC0tY29sb3ItYm9yZGVyX18xYmw3amoxeSk7Cn0KLnhndGU1NTYgewogIGhlaWdodDogY2FsYygoKCgxMDB2aCAtIDYwcHgpIC0gKDEyMHB4ICsgdmFyKC0tc3BhY2luZy1sX18xYmw3amoxMWwpKSkgLSB2YXIoLS1ob3Jpem9udGFsLXNjcm9sbGJhci1oZWlnaHQpKSArIHZhcigtLXNwYWNpbmctbF9fMWJsN2pqMTFsKSk7Cn0=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var foldersWidth = '278px';
export var sidebarWidth = '360px';
export var sidebarZIndex = 501;
export var sidebarBoxShadow = 'xgte550';
export var sidebarHeader = 'xgte551';
export var sidebarInner = _7a468({defaultClassName:'xgte552',variantClassNames:{withFoldersOffset:{true:'xgte553',false:'xgte554'}},defaultVariants:{},compoundVariants:[]});
export var sidebarContent = 'xgte555';
export var editorNavigationHeight = '60px';
export var sidebarHeaderComponent = '120px';
export var sidebarHeaderPadding = 'var(--spacing-l__1bl7jj11l)';
export var sidebarViewContainer = 'xgte556';