"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoExploreDataStore = void 0;
var lodash_1 = require("lodash");
var SidebarExploreDataStore_1 = require("../SidebarExploreDataStore");
var mobx_1 = require("mobx");
var data_1 = require("./data");
var incrementSize = 10;
var VideoExploreDataStore = (function (_super) {
    __extends(VideoExploreDataStore, _super);
    function VideoExploreDataStore() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.searchFilter = "";
        _this.loadedAssetCount = incrementSize;
        _this.fetchNextIncrement = function () {
            _this.loadedAssetCount += incrementSize;
        };
        return _this;
    }
    Object.defineProperty(VideoExploreDataStore.prototype, "loadedAssets", {
        get: function () {
            return (0, lodash_1.take)(this.totalAssets, this.loadedAssetCount);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VideoExploreDataStore.prototype, "hasUnloadedAssets", {
        get: function () {
            return this.loadedAssetCount < this.totalAssets.length;
        },
        enumerable: false,
        configurable: true
    });
    VideoExploreDataStore.prototype.search = function (term) {
        if (this.searchFilter !== term) {
            this.reset();
            this.searchFilter = term;
        }
    };
    VideoExploreDataStore.prototype.clearSearch = function () {
        this.reset();
        this.searchFilter = "";
    };
    VideoExploreDataStore.prototype.reset = function () {
        this.loadedAssetCount = incrementSize;
    };
    Object.defineProperty(VideoExploreDataStore.prototype, "totalAssets", {
        get: function () {
            var _this = this;
            if (!!this.searchFilter) {
                return data_1.videoList.filter(function (video) { return video.name.toLowerCase().includes(_this.searchFilter.toLowerCase().trim()); });
            }
            return data_1.videoList;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        mobx_1.observable,
        __metadata("design:type", Object)
    ], VideoExploreDataStore.prototype, "searchFilter", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Number)
    ], VideoExploreDataStore.prototype, "loadedAssetCount", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], VideoExploreDataStore.prototype, "loadedAssets", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], VideoExploreDataStore.prototype, "hasUnloadedAssets", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], VideoExploreDataStore.prototype, "fetchNextIncrement", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], VideoExploreDataStore.prototype, "search", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], VideoExploreDataStore.prototype, "clearSearch", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], VideoExploreDataStore.prototype, "totalAssets", null);
    return VideoExploreDataStore;
}(SidebarExploreDataStore_1.BaseExploreDataStore));
exports.VideoExploreDataStore = VideoExploreDataStore;
