"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var slate_react_1 = require("slate-react");
var EditorTypes_1 = require("../EditorTypes");
var BehaviourBuilder_1 = require("./BehaviourBuilder");
var CopyActions = __importStar(require("./CopyActions"));
var PasteActions = __importStar(require("./PasteActions"));
var SyntheticSelectionActions = __importStar(require("./SyntheticSelectionActions"));
exports.default = (function (di) {
    var syntheticSelectionStore = di.get("syntheticSelectionStore");
    return [
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("copyWithSyntheticSelection")
            .isEvent(EditorTypes_1.EventType.SyntheticSelection)
            .withData({ action: "copy" })
            .doAction(function (event, editor) {
            event.data.originalEvent.preventDefault();
            CopyActions.copyWidgetAtPath(event.data.originalEvent, editor, event.data.path);
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("cutWithSyntheticSelection")
            .isEvent(EditorTypes_1.EventType.SyntheticSelection)
            .withData({ action: "cut" })
            .doAction(function (event, editor) {
            event.data.originalEvent.preventDefault();
            CopyActions.cutWidgetAtPath(event.data.originalEvent, editor, event.data.path);
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("pasteWithSyntheticSelection")
            .isEvent(EditorTypes_1.EventType.SyntheticSelection)
            .withData({ action: "paste" })
            .doAction(function (event, editor) {
            PasteActions.insertAndReplaceAtPath(editor, event.data.originalEvent.fragment, event.data.path);
            slate_react_1.ReactEditor.focus(editor);
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("enterWithSyntheticSelection")
            .isEvent(EditorTypes_1.EventType.SyntheticSelection)
            .withData({ action: "enter" })
            .doAction(function (event, editor) {
            event.data.originalEvent.preventDefault();
            SyntheticSelectionActions.newParagraphBelowWidgetAtSelection(event.data.selection);
            syntheticSelectionStore.clearSelection();
            slate_react_1.ReactEditor.focus(editor);
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("backspaceWithSyntheticSelection")
            .isEvent(EditorTypes_1.EventType.SyntheticSelection)
            .withData({ action: "backspace" })
            .doAction(function (event, editor) {
            event.data.originalEvent.preventDefault();
            SyntheticSelectionActions.removeWidgetsAtSelection(event.data.selection, "backward");
            syntheticSelectionStore.clearSelection();
            slate_react_1.ReactEditor.focus(editor);
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("deleteWithSyntheticSelection")
            .isEvent(EditorTypes_1.EventType.SyntheticSelection)
            .withData({ action: "delete" })
            .doAction(function (event, editor) {
            event.data.originalEvent.preventDefault();
            SyntheticSelectionActions.removeWidgetsAtSelection(event.data.selection, "forward");
            syntheticSelectionStore.clearSelection();
            slate_react_1.ReactEditor.focus(editor);
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("undoWithSyntheticSelection")
            .isEvent(EditorTypes_1.EventType.SyntheticSelection)
            .withData({ action: "undo" })
            .doAction(function (event, editor) {
            event.data.originalEvent.preventDefault();
            editor.undo();
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("arrowUpWithSyntheticSelection")
            .isEvent(EditorTypes_1.EventType.SyntheticSelection)
            .withData({ action: "arrowUp" })
            .doAction(function (event, editor) {
            event.data.originalEvent.preventDefault();
            SyntheticSelectionActions.moveToSiblingWidgetFromSelection(event.data.selection, "backwards");
            syntheticSelectionStore.clearSelection();
            slate_react_1.ReactEditor.focus(editor);
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("arrowDownWithSyntheticSelection")
            .isEvent(EditorTypes_1.EventType.SyntheticSelection)
            .withData({ action: "arrowDown" })
            .doAction(function (event, editor) {
            event.data.originalEvent.preventDefault();
            SyntheticSelectionActions.moveToSiblingWidgetFromSelection(event.data.selection, "forwards");
            syntheticSelectionStore.clearSelection();
            slate_react_1.ReactEditor.focus(editor);
            return true;
        }),
    ];
});
