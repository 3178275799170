import "Application/Library/AutomationLibrary/AutomationCard.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Library/AutomationLibrary/AutomationCard.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71TTW/bMAy951foMmA9KIjTrV2V27D79g8KWWIcuoooSLSTbNh/HyRvjrqk+zrsZIiPfHyPpJd0y70dVuLLQohACRnJKxHBacYRNgshdApgWEbNSEo0y/VdjgZtLfpOiVHH11KmoA36TrrHx6Z1933fNO5ms/i6WE4NmtLADDFRVMLCVg+OK3xd8B1gt2MlmtXqVQXeFtBiCk6flNg6OGYJ+SstRjCTaENu2PuMdDr8LOyYzsqwVvbmOnk/JMbtSRryDJ6VyEQgW+ADQGmiHXZeIsM+TWUysY61qbfPqeUB2idk2VLpMMe/v3/ADj1I4/Q+KLHOwEWgIpIUscgbITIa7TLOcGRJI8Sto4MS4ByGhClD5+gOrc1GZrV304rI5Q1NwysPmek+YNKtAzsP0dYzvP8ro5ca/ov5We27fzf6UEpbihaiZApKNOEoEjm0mzNwhXpCZtLTTfUPTUTPz7W61n7KJfQMUcIInpMSemC6uEIDOafSq2u9V2Tl647k3peEC+vNuvbevjQ2NOTnmlCXmD8aF1P4rbaZ/3PNb3+1yY/+U8S9jqe5Vufab+dXV93yBAAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var cardWrapper = _7a468({defaultClassName:'o3tjdu0',variantClassNames:{disabled:{true:'o3tjdu1'}},defaultVariants:{},compoundVariants:[]});
export var cardContent = 'o3tjdu2';
export var cardHeadingBlock = 'o3tjdu3';
export var cardHeadingRow = 'o3tjdu4';
export var automationName = _7a468({defaultClassName:'o3tjdu5',variantClassNames:{disabled:{true:'o3tjdu6'}},defaultVariants:{},compoundVariants:[]});
export var description = _7a468({defaultClassName:'o3tjdu7',variantClassNames:{disabled:{true:'o3tjdu8'}},defaultVariants:{},compoundVariants:[]});
export var bottomRow = _7a468({defaultClassName:'o3tjdu9',variantClassNames:{disabled:{true:'o3tjdua'}},defaultVariants:{},compoundVariants:[]});
export var chevron = 'o3tjdub';
export var destructiveOption = 'o3tjduc';
export var tooltipText = 'o3tjdud';