"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterButtonConfigs = void 0;
var EditorTypes_1 = require("Project/QwilrEditor/EditorTypes");
var Utils_1 = require("Project/QwilrEditor/Utils");
var slate_1 = require("slate");
var EditorWidgetRegistry_1 = require("Project/QwilrEditor/WidgetRegistry/EditorWidgetRegistry");
function filterButtonConfigs(editor, buttonConfigs, isSavedBlock) {
    return buttonConfigs.filter(function (button) {
        if (isSavedBlock && button.type === EditorTypes_1.MarkType.Comment) {
            return false;
        }
        if (insideTableCell(editor) && [EditorTypes_1.MarkType.Style].includes(button.type)) {
            return false;
        }
        if ((0, Utils_1.isInsideAccordionHeading)(editor) && ["listItem", EditorTypes_1.InlineType.Link].includes(button.type)) {
            return false;
        }
        if ((0, Utils_1.isInsideAccordion)(editor) && button.type === EditorTypes_1.MarkType.Style) {
            return false;
        }
        var config = (0, EditorWidgetRegistry_1.getGenericConfigByType)(button.type);
        if (config && insideTableCell(editor) && !config.validContainers.includes("tableCell")) {
            return false;
        }
        if (config && (0, Utils_1.isInsideAccordionHeading)(editor) && !config.validContainers.includes("accordionHeading")) {
            return false;
        }
        return true;
    });
}
exports.filterButtonConfigs = filterButtonConfigs;
var insideTableCell = function (editor) {
    if (!editor.selection) {
        return false;
    }
    return (0, Utils_1.hasAncestorOf)(editor, slate_1.Range.start(editor.selection).path, "tableCell");
};
