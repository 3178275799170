"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DragType = exports.DragStore = void 0;
var mobx_1 = require("mobx");
var DragType;
(function (DragType) {
    DragType["Variable"] = "dragtype:variable";
    DragType["SavedBlock"] = "dragtype:savedblock";
    DragType["ImageWidgetFromEditor"] = "dragtype:imagewidgetfromeditor";
    DragType["Widget"] = "dragtype:widget";
    DragType["SidebarImage"] = "dragtype:sidebarimage";
    DragType["SidebarVideo"] = "dragtype:sidebarvideo";
    DragType["SidebarBlockModel"] = "dragtype:sidebarblockmodel";
})(DragType || (exports.DragType = DragType = {}));
var TRACE_DRAG_CHANGES = false;
var DragStore = (function () {
    function DragStore() {
        var _this = this;
        this._dragItems = [];
        this.findDragItemFromCurrentDrag = function (dragType) {
            return (0, mobx_1.computed)(function () {
                var dragItem = _this._dragItems.find(function (item) { return (item === null || item === void 0 ? void 0 : item.type) === dragType; });
                if (!dragItem)
                    return undefined;
                return dragItem;
            }).get();
        };
        if (TRACE_DRAG_CHANGES) {
            (0, mobx_1.autorun)(function () {
                console.log("Current drag items:", _this._dragItems);
            });
        }
    }
    DragStore.prototype.startDrag = function (dragItems) {
        this._dragItems = dragItems;
    };
    DragStore.prototype.stopDrag = function () {
        this._dragItems = [];
    };
    Object.defineProperty(DragStore.prototype, "dragItems", {
        get: function () {
            return this._dragItems;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DragStore.prototype, "isDragging", {
        get: function () {
            return this._dragItems.length > 0;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Array)
    ], DragStore.prototype, "_dragItems", void 0);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", void 0)
    ], DragStore.prototype, "startDrag", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], DragStore.prototype, "stopDrag", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], DragStore.prototype, "dragItems", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], DragStore.prototype, "isDragging", null);
    return DragStore;
}());
exports.DragStore = DragStore;
