import "Application/Common/MultiSelectCombobox/MultiSelectCombobox.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Common/MultiSelectCombobox/MultiSelectCombobox.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7WUsVLjMBCG+zzFlqQQE4ccEDFzDbwBBSUjS2tFOVnySHLscHPvfmMRGxldIM01yUj5//03n3d9vb/fdobfwO8FgFC+0exIodLYPywgfpPOsYbC8DlcyeFwYO6KEN8wrowkfe9fX4tS3+33RbFbDqqaOakMCTYXJ1q1fFj8WVy/t7CJLXxhzFO41daNunggAfvw6FRQnOlJvk1zfsQcQt5Pq7ndt65iHJ+RWyOYO04lVAwcXUUeOik3M+V6rlTcmknZRGUGnWklDVEBa0+BownozoH/xBKgZPyXdLY1gszgnP5s1DRMCGXkBXxPSqKxChdkWyfQEceEaj2FTdMn1G8voJ49t/o76P980l/CzxyHdDbu/s9sPCnPSo1icojv2swcXdrmfWxzmBcKK1gBa4PN9uFUO/Vto88e0FXadqfFZubY7dDhmQJFWoDFAmdipuUtbQi2pkDWsxFg8BP8QcYSnRJhR2G9minK+NtAjATHjK+sqykYa2JzlTWBePWGY/Rw8azeMDJOB3Ltl5OhQyV3IbW8xBtSo1Bt/eE5Ro/GENCNU06hNR5D0iLPCZzZ7Mki0hfbyObz7qXrJJezwIxacRup/QUUc6cJuwUAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var selectedValues = 'j89wnc3';
export var errorText = 'j89wnc4';
export var selectionCard = _7a468({defaultClassName:'j89wnc5',variantClassNames:{error:{true:'j89wnc6'},disabled:{true:'j89wnc7'}},defaultVariants:{},compoundVariants:[]});
export var selectionCardIcon = 'j89wnc8';
export var selectionCardLabelText = 'j89wnc9';
export var selectionCardLabelIcon = 'j89wnca';
export var selectionCardTooltip = 'j89wncb';
export var comboboxEntryIcon = 'j89wncc';
export var comboboxEntryLabel = 'j89wncd';