"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClickToAddStore = void 0;
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var WidgetManager_1 = require("../WidgetManager");
var InvalidReason_1 = require("./InvalidReason");
var lodash_1 = require("lodash");
var Types_1 = require("Project/ContentSidebar/Types");
var ClickToAddStore = (function () {
    function ClickToAddStore(notifyUser) {
        var _this = this;
        this.notifyUser = notifyUser;
        this.updateLastKnownSelection = function (widgetManager) {
            _this.clearCurrentEntry();
            var editor = widgetManager.editor;
            var selection = editor.selection;
            _this.lastKnownEntry = {
                widgetManager: widgetManager,
                selection: selection,
            };
        };
        this.clickToAdd = function (_a) {
            var incomingMediaData = _a.mediaData, mediaSource = _a.mediaSource, telemetryData = _a.telemetryData;
            var type = incomingMediaData.type, mediaDataWithoutType = __rest(incomingMediaData, ["type"]);
            var widgetType = type === Types_1.AssetType.Video ? "video" : "image";
            if ((0, lodash_1.isNil)(_this.lastKnownEntry)) {
                _this.notifyForInvalidClickToAdd(InvalidReason_1.InvalidReason.EditorNoLongerExists, widgetType);
                return;
            }
            var _b = _this.lastKnownEntry, selection = _b.selection, widgetManager = _b.widgetManager;
            var mediaData = __assign({ type: type }, mediaDataWithoutType);
            widgetManager.triggerEvent(new WidgetManager_1.WidgetEvent(widgetType, {
                action: "clickToAdd",
                selection: selection,
                mediaData: mediaData,
                mediaSource: mediaSource,
                telemetryData: telemetryData,
            }));
        };
        this.deregisterEditor = function (editor) {
            if ((0, lodash_1.isNil)(_this.lastKnownEntry)) {
                return;
            }
            if (_this.lastKnownEntry.widgetManager.editor === editor) {
                _this.clearCurrentEntry();
            }
        };
        this.notifyForInvalidClickToAdd = function (invalidReason, assetType) {
            if (assetType === void 0) { assetType = "image"; }
            _this.notifyUser.showNotification("Select a location in the editor to add this ".concat(assetType, "."), kaleidoscope_1.NotificationType.Info);
            console.error("Click to add failed because of reason: ", invalidReason);
        };
        this.clearCurrentEntry = function () {
            _this.lastKnownEntry = null;
        };
    }
    return ClickToAddStore;
}());
exports.ClickToAddStore = ClickToAddStore;
