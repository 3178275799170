import "Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6WVwW7iMBCG7zzFCGkl0GIUhwRoUI/7AntdVciJBzANdtY2lLLad1/FCU1sqNpVxcmZL/8/mRkP0zWV4pJfeAR/BgCVMsIKJTPQWDIrTrgaAOxQbHc2AxpF31aDv4Pp9SXqXnoR3O7uRON+dCMsKZS0KG0N/eLMMmKwxKL2I1rI7ZPji6M2SmdQKSEt6g52R1bUSZGLkvg0aUVKZpFIxfFxiCUeUNqhL8Vxw45lz7fFiH2t8HFo8Wx/3H2xjnifNHdhQq7nJINo5dWN5UaVR+vqdudTOqUsy3GjNDaGTWUyGA7fl8vVmZgd4+olg8j94uoMJ6ZHXULpuCZVxQphX7MgmLhgzornrVZHyUmhSveZmklTMe16A82x9W+VWiF+1My1y6zXNC8X+z2lZtwGkRkht8RYJjnTvCN+jye93L8uFeT/RUGvK4vb/tKV9yTNQG/zUTxLIU4iiJOFr7D8rAJNHyCmCcRp4is8fFphDjSdQZzGvgBzAkIatG48+yFv6jqkHi7NURPNuDiacHBmE4iqs++S9yUIjauzH77nlNTQx17Ng4b66SC3OrQqu8bh2E+Ht/vL3TSCJ5TWZCCVdFfnmmYSZMnfr8fNLalLvlwCXdY9m43DG9lY9bQ3d3bFlHrI9h4Se8g+2Mv9hWBVFVZPuLx0s68LVhajei3D9wDbBdXb//82cubt6FirDu2hxM3bP8Xb8g/NPe/noAgiHNryFiA0Cjp5CKBdBvMAkbcIDQeiumWWDvkHbuL8LykHAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "Application/Project/QwilrEditor/RegistryElement/Resizer.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/RegistryElement/Resizer.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VWTY+bMBC951dYK60U1DjFZLNJnVNvPffW08rgSXBCbGqbJN2q/73CwILJR9tlq71hz8zjzZsPmD4RvsjlbhmhnyOEcmWEFUpSxGKjssLCaoTQUXCbUnRgeoxxExAGpcmqnKKwfIqVtWrfHFiy22hVSE6R3sRj8ojIfIaieYQ+onAazV3wMxaSw4kisnLvFtKCxnAAaQ1FUkmogDUHjTXjojANi+ryq7vDptBrlsDTE4mzxXZLyNrBq5wlwv6oKVnNZJNcbamxeKFZeY+zFqEIaiMwI+QGG8skZ5q3Ht+DSSfNgVCr0a/RtFH2wZWiZT9deuZHZ/6DwnMfcuFiMlhbp0bHsnQWLTbpmemTH3TFi/W9nNBrpfe0esyYhRkf+91DggkKJyj0acZviAUO6y+qbtpCmH+sOk5UpvRAwPNWvTmFZJmfymMKVTWac29MSBjel9dJoY3StJmuW4P2Mo6RLySNYa10pWeipAVpKbq7u87ULYVZRasqZn1oUoh6KUQ3U+jrXfdCzjRI61Hd+JNzca0IKaxgmR9Huzmev++YCtvZRRSR/ISMykQz984Pl+JolX1OEpD2izqAbuv8EFTxJ2xSxtWxWWTVCUMGB9c9pA2J/YZOX5lc+h+Sa0nOBuclHK92nBOKFmU/dFy25y6477Pr7I6EZcm4tyWSAH3ofcJ4ECBc9mb7KZtXLXdp73wb43l4H3SHCo5YgxHP4DHJuiv1fansqyVYwr2KyAA5G2LyaOASNTmQ2hB9X7iBuSibqkal/qN5N+WusMuHs3sT8bzC/gbWOoFUSQoAAA==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var snapAreaWidth = 'var(--_1d7pnk80)';
export var snapAreaXOffset = 'var(--_1d7pnk81)';
export var resizeableSnapArea = _7a468({defaultClassName:'_1d7pnk82',variantClassNames:{visible:{false:'_1d7pnk83',true:'_1d7pnk84'},highlighted:{false:'_1d7pnk85',true:'_1d7pnk86'}},defaultVariants:{},compoundVariants:[]});
export var resizeableSnapAreaContentStart = '_1d7pnk87';
export var resizeableSnapAreaContentEnd = '_1d7pnk88';
export var resizableSnapAreaContent = '_1d7pnk89';
export var snapAreaWide = '_1d7pnk8a';
export var snapAreaFull = '_1d7pnk8b';
export var resizeHandleSelectionRingOffset = 'var(--_1d7pnk8d)';
export var resizeHandle = _7a468({defaultClassName:'_1d7pnk8e',variantClassNames:{state:{idle:'_1d7pnk8f',hover:'_1d7pnk8g',active:'_1d7pnk8h'},inset:{true:'_1d7pnk8i',false:'_1d7pnk8j'},position:{left:'_1d7pnk8k',right:'_1d7pnk8l',topLeft:'_1d7pnk8m',topRight:'_1d7pnk8n',bottomLeft:'_1d7pnk8o',bottomRight:'_1d7pnk8p'}},defaultVariants:{},compoundVariants:[]});