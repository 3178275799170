"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPathToNodeBasedOnKey = exports.getPath = exports.createVariableNode = exports.processSelection = exports.processInline = exports.processBlock = exports.deserialiseNode = exports.deserialise = void 0;
var lodash_1 = require("lodash");
var Utils_1 = require("../Utils");
var Common_1 = require("ContentTokens/Models/Common");
var Text_1 = require("ContentTokens/Models/Text");
var mobx_1 = require("mobx");
var EditorWidgetRegistry_1 = require("Project/QwilrEditor/WidgetRegistry/EditorWidgetRegistry");
var utils_1 = require("@qwilr/kaleidoscope/utils");
var IsResizeableWidget_1 = require("@CommonFrontend/WidgetRegistry/Utils/IsResizeableWidget");
var IsAlignableWidget_1 = require("@CommonFrontend/WidgetRegistry/Utils/IsAlignableWidget");
var deserialise = function (tokens) {
    tokens = (0, mobx_1.toJS)(tokens);
    var commentTracker = {};
    var nodesAndSelection = (0, exports.deserialiseNode)(tokens, commentTracker);
    return nodesAndSelection;
};
exports.deserialise = deserialise;
var deserialiseNode = function (tokens, commentTracker) {
    var content = {
        children: [],
    };
    var currentBlock = null;
    var lastNode = null;
    for (var t = 0; t < tokens.length; t++) {
        var token = tokens[t];
        switch (token.type) {
            case "block":
                if (currentBlock != null) {
                    addBlockToContent(content, currentBlock);
                }
                currentBlock = (0, exports.processBlock)(token, content, commentTracker);
                lastNode = currentBlock;
                break;
            case "inline":
                if (currentBlock == null) {
                    break;
                }
                var newNode = (0, exports.processInline)(token, commentTracker);
                if (nodesCanMerge(lastNode, newNode)) {
                    mergeNodeInto(lastNode, newNode);
                }
                else {
                    currentBlock.children.push(newNode);
                    lastNode = newNode;
                }
                break;
            case "selection":
                (0, exports.processSelection)(token, content, lastNode, currentBlock, tokens[t + 1]);
                break;
            case "comment":
                if (token.subType === "start") {
                    commentTracker[token.id] = token;
                }
                else if (token.subType == "end") {
                    delete commentTracker[token.id];
                }
                break;
            default:
                throw new Error("invalid token ".concat(token.type));
        }
    }
    if (currentBlock != null) {
        addBlockToContent(content, currentBlock);
    }
    return content;
};
exports.deserialiseNode = deserialiseNode;
var addBlockToContent = function (content, currentBlock) {
    if (currentBlock.children.length === 0 && currentBlock.type !== "table") {
        currentBlock.children.push({
            text: "",
            marks: [],
        });
    }
    content.children.push(currentBlock);
};
var processBlock = function (token, content, commentTracker) {
    var _a;
    if (token.subType === Common_1.BlockType.MediaVariable && !!(0, EditorWidgetRegistry_1.getGenericConfigByType)(token.media)) {
        var media = token.media, subType = token.subType, tokenWithoutMedia = __rest(token, ["media", "subType"]);
        token = __assign(__assign({}, tokenWithoutMedia), { subType: token.media, variable: __assign({ label: token.label, name: token.name }, (0, utils_1.includeIf)(!!token.substitutionToken, {
                substitutionToken: token.substitutionToken,
            })) });
    }
    var deserialisedFromRegistry = (_a = (0, EditorWidgetRegistry_1.getGenericConfigByType)(token.subType)) === null || _a === void 0 ? void 0 : _a.deserialise(token, content, commentTracker);
    if (deserialisedFromRegistry) {
        if ((0, IsResizeableWidget_1.isResizeableWidget)(token)) {
            var width = token.width === "normal" ? "wide" : token.width;
            deserialisedFromRegistry.data.width = width;
            if (token.height) {
                deserialisedFromRegistry.data.height = token.height;
            }
        }
        if ((0, IsAlignableWidget_1.isAlignableWidget)(token)) {
            deserialisedFromRegistry.data.alignment = token.alignment;
        }
        if ((0, EditorWidgetRegistry_1.isStyleableWidget)(token)) {
            deserialisedFromRegistry.style = token["style"];
        }
        return deserialisedFromRegistry;
    }
    var currentBlock = {
        type: token.subType,
        children: [],
        data: {},
        isVoid: false,
    };
    switch (token.subType) {
        case Common_1.BlockType.Caption:
            currentBlock.type = "paragraph";
            currentBlock.data = {};
            break;
        default:
            console.error(new Error("unrecognised block type: ".concat(token.subType)));
            return null;
    }
    return currentBlock;
};
exports.processBlock = processBlock;
var processInline = function (token, commentTracker) {
    if (token.subType == Common_1.InlineType.LineBreak) {
        return createBreakNode(token, commentTracker);
    }
    else if (token != null && token.subType === Common_1.InlineType.Variable) {
        return (0, exports.createVariableNode)(token, commentTracker);
    }
    else if (token != null && token.href != null) {
        return createLinkNode(token, commentTracker);
    }
    else {
        return createTextNode(token, commentTracker);
    }
};
exports.processInline = processInline;
var processSelection = function (token, content, lastNode, currentBlock, nextToken) {
    if (!content.selection) {
        content.selection = {
            anchor: {},
            focus: {},
        };
    }
    if (!lastNode) {
        throw new Error("invalid token set");
    }
    if (lastNode.isVoid) {
        lastNode = lastNode.children[0];
    }
    if (!content.selection) {
        throw new Error("Selection should have been defined by now");
    }
    if (token.subType === "anchor" || token.subType === "focus") {
        var location = token.subType;
        lastNode.key = token.subType;
        var path = (0, exports.getPath)(token.subType, content.children.concat(currentBlock)) || [];
        var offset = calculateOffset(lastNode);
        if ((0, Utils_1.isInline)(lastNode) && lastNode.type === Common_1.InlineType.Link) {
            path.push(lastNode.children.length - 1);
        }
        else if ((0, Utils_1.isInline)(lastNode)) {
            path.push(0);
        }
        else if ((0, Utils_1.isBlockElement)(lastNode)) {
            path.push(0);
            if (nextToken.type == "inline" && (nextToken.subType !== "text" || nextToken.href != undefined)) {
                path.push(0);
            }
        }
        content.selection[location].path = path;
        content.selection[location].offset = offset;
        delete lastNode.key;
    }
    else {
        throw new Error("Unknown selection subtype");
    }
};
exports.processSelection = processSelection;
var calculateOffset = function (lastNode) {
    if ((0, Utils_1.isBlockElement)(lastNode)) {
        return 0;
    }
    else if (Text_1.Text.isText(lastNode)) {
        return lastNode.text.length;
    }
    else if ((0, Utils_1.isInline)(lastNode)) {
        var offset = calculateOffset(lastNode.children[lastNode.children.length - 1]);
        return offset;
    }
    else {
        throw new Error("Trying to calculate offset for unknown node");
    }
};
var createVariableNode = function (persistedToken, commentTracker, isVoid) {
    if (isVoid === void 0) { isVoid = true; }
    var slateNode = {
        type: Common_1.InlineType.Variable,
        isVoid: isVoid,
        data: {
            variableName: persistedToken.name,
            variableLabel: persistedToken.label,
            isBold: persistedToken.isBold,
            isItalic: persistedToken.isItalic,
            isStrikethrough: persistedToken.isStrikethrough,
            providerType: persistedToken.providerType,
        },
        children: [createEmptyTextNode()],
    };
    if (persistedToken.substitutionToken) {
        slateNode.data.substitutionToken = persistedToken.substitutionToken;
    }
    return slateNode;
};
exports.createVariableNode = createVariableNode;
var createLinkNode = function (token, commentTracker) {
    return {
        type: Common_1.InlineType.Link,
        data: {
            href: token.href,
        },
        children: [createTextNode(token, commentTracker)],
    };
};
var createBreakNode = function (token, commentTracker) {
    return {
        type: Common_1.InlineType.LineBreak,
        isVoid: true,
        children: [createEmptyTextNode()],
    };
};
var createTextNode = function (token, commentTracker) {
    var textNode = {
        text: token.content,
        marks: getTokenMarks(token, commentTracker),
    };
    return textNode;
};
var createEmptyTextNode = function () {
    return { text: "", marks: [] };
};
var nodeHasKey = function (node) {
    return !!node.key;
};
var getTokenMarks = function (token, commentTracker) {
    if (commentTracker === void 0) { commentTracker = {}; }
    var marks = [];
    if (token.style) {
        if (token.style.bold) {
            marks.push({ data: {}, type: "bold" });
        }
        if (token.style.italic) {
            marks.push({ data: {}, type: "italic" });
        }
        if (token.style.strikethrough) {
            marks.push({ data: {}, type: "strikethrough" });
        }
    }
    Object.values(commentTracker).forEach(function (comment) {
        marks.push({ data: { id: comment.id }, type: "comment" });
    });
    return marks;
};
var nodesCanMerge = function (a, b) {
    if (!a || !b || getNodeObject(a) != getNodeObject(b)) {
        return false;
    }
    switch (getNodeObject(a)) {
        case "block":
            return false;
        case "inline":
            if (a.type === Common_1.InlineType.Variable || b.type === Common_1.InlineType.Variable) {
                return false;
            }
            if (a.type === Common_1.InlineType.Link && a.type === b.type) {
                return a.data.href === b.data.href;
            }
            return false;
        case "text":
            return (0, lodash_1.isEqual)(a.marks, b.marks);
        default:
            throw new Error("Trying to match unknown node object types");
    }
};
var mergeNodeInto = function (baseNode, otherNode) {
    switch (getNodeObject(baseNode)) {
        case "block":
            throw new Error("Cannot merge block nodes");
        case "inline":
            mergeInline(baseNode, otherNode);
            return;
        case "text":
            baseNode.text = baseNode.text + otherNode.text;
            return;
        default:
            throw new Error("Trying to merge unknown node object types");
    }
};
var getPath = function (key, value) {
    for (var i = 0; i < value.length; i++) {
        var node = value[i];
        if (nodeHasKey(node) && node.key == key) {
            return [i];
        }
        if (node.children && node.children.length > 0) {
            var maybePath = (0, exports.getPath)(key, node.children);
            if (maybePath) {
                return [i].concat(maybePath);
            }
        }
    }
    return null;
};
exports.getPath = getPath;
exports.getPathToNodeBasedOnKey = exports.getPath;
var getNodeObject = function (node) {
    if (Text_1.Text.isText(node)) {
        return "text";
    }
    else if ((0, Utils_1.isInline)(node)) {
        return "inline";
    }
    else {
        return "block";
    }
};
var mergeInline = function (baseNode, otherNode) {
    var e_1, _a;
    var prevChild = baseNode.children[baseNode.children.length - 1];
    try {
        for (var _b = __values(otherNode.children), _c = _b.next(); !_c.done; _c = _b.next()) {
            var child = _c.value;
            if (nodesCanMerge(prevChild, child)) {
                prevChild.text = prevChild.text + child.text;
            }
            else {
                prevChild = child;
                baseNode.children.push(child);
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
};
